import React from "react";
import { CopyBlock, anOldHope } from "react-code-blocks";
import Navigation from "../Navigation";
import SEOHelmet from "../../utils/SEOHelmet";

function PublishSDK() {
    const codeSnippets = `import vipas
vipas.model.ModelClient.publish(
    model_id: str, 
    model_folder_path: Optional[str] = None, 
    model_framework_type: str, 
    onnx_config_path: Optional[str] = None, 
    hf_config_path: Optional[str] = None, 
    mlflow_config_path: Optional[str] = None, 
    processor_folder_path: Optional[str] = None, 
    processor_image: Optional[str] = None, 
    auto_launch: bool = True, 
    override_model: bool = True
) → dict`;

    const exampleSnippet = `from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException


# Paths to model and processor files
model_folder_path = "/path/to/your/model"
onnx_config_path = "/path/to/config/config.pbtxt"  # Optional, depends on framework
processor_folder_path = "/path/to/your/processor"

# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx

try:
    # Initialize the ModelClient
    model_client = ModelClient()

    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_folder_path=model_folder_path,
        model_framework_type="tensorflow",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn.
        onnx_config_path=onnx_config_path,  # Required for the ONNX model framework        
        processor_folder_path=processor_folder_path,  # Optional if using custom processors
        processor_image="your-processor-image:latest",  # allowed value are [“vps-processor-base:1.0”]
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")`;

    const codeSnip = {
        generalPublish: `from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException
        
# Paths to model and processor files
model_folder_path = "/path/to/your/model"
processor_folder_path = "/path/to/your/processor"
        
# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx
        
try:
    # Initialize the ModelClient
    model_client = ModelClient()
        
    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_folder_path=model_folder_path,
        model_framework_type="tensorflow",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn, hugging_face_qwen_2.5, hugging_face_llama_3, hugging_face_google_t5, hugging_face_google_mt5, hugging_face_coedit_large, hugging_face_dslim_bert, hugging_face_distilbert mlflow etc.        
        processor_folder_path=processor_folder_path,  # Optional if using custom processors
        processor_image="your-processor-image:latest",  # allowed value are ["vps-processor-base:1.0"]
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True, If set to False, the model will be placed in a staged state.
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")`,
        onnxPublish: `from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException
    
    
# Paths to model and processor files
model_folder_path = "/path/to/your/model"
processor_folder_path = "/path/to/your/processor"
onnx_config_path = "/path/to/config/config.pbtxt"  # Optional, depends on framework
    
# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx
    
try:
    # Initialize the ModelClient
    model_client = ModelClient()
    
    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_folder_path=model_folder_path,
         model_framework_type="onnx",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn, hugging_face_qwen_2.5, hugging_face_llama_3, hugging_face_google_t5, hugging_face_google_mt5, hugging_face_coedit_large, hugging_face_dslim_bert, hugging_face_distilbert, mlflow etc.
        onnx_config_path=onnx_config_path,  # Required for the ONNX model framework        
        processor_folder_path=processor_folder_path,  # Optional if using custom processors
        processor_image="your-processor-image:latest",  # allowed value are ["vps-processor-base:1.0"]
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True, If set to False, the model will be placed in a staged state.
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")
`,
        onnxFileData: `input [
    {
      name: "input1"  # Name of the input going to the model (input tensor)
      data_type: TYPE_FP32  # Data type of the input, FP32 stands for 32-bit floating point (commonly used in deep learning)
      dims: [1, 3, 224, 224]  # Dimensions of the input tensor: [Batch size, Channels, Height, Width]
    }
   ]
   output [
    {
      name: "output1"  # Name of the output from the model (output tensor)
      data_type: TYPE_FP32  # Data type of the output, FP32 represents 32-bit floating point
      dims: [1, 3, 224, 224]  # Dimensions of the output tensor: [Batch size, Channels, Height, Width]
    }
]`,
        huggingFaceModel: `from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException

# Paths to hugging face config files
hugging_face_config_path = "/path/to/config/config.json"  # Optional, Path to the Hugging Face config file which contains essential details like the Hugging Face access token, model ID, and maximum token limit.

# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx

try:
    # Initialize the ModelClient
    model_client = ModelClient()

    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_framework_type="hugging_face_llama_3",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn, hugging_face_qwen_2.5, hugging_face_llama_3, hugging_face_google_t5, hugging_face_google_mt5, hugging_face_coedit_large, hugging_face_dslim_bert, hugging_face_distilbert, mlflow etc.
        hf_config_path=hugging_face_config_path,  # Required for the Hugging Face model framework
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True, If set to False, the model will be placed in a staged state.
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")`,
        hugginFileContent: `{
    "hf_access_token":"<hugging face access token>",  # Required, a valid Hugging Face access token for model creation. You can obtain your Hugging Face access token from your account's 'Access Tokens' section on the Hugging Face website.
    "hf_model_id":"<hugging face model id>", # Required, the unique identifier of the Hugging Face model. Framework-specific IDs are listed in the above table. 
    "hf_max_token": 100 # The maximum token limit, which is an integer value between 1 and 500. If not specified, the default value is 100.
}`,
        mlflowModel: `from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException


# Paths to MLflow config files
mlflow_config_path = "/path/to/mlflow_config.json"  # Optional, Path to the MLflow config file which contains details like the model docker image and a valid docker personal access token. Both are required.

# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx

try:
    # Initialize the ModelClient
    model_client = ModelClient()

    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_framework_type="mlflow",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn, hugging_face_qwen_2.5, hugging_face_llama_3, hugging_face_google_t5, hugging_face_google_mt5, hugging_face_coedit_large, hugging_face_dslim_bert, hugging_face_distilbert, mlflow etc.
        mlflow_config_path=mlflow_config_path,  # Required for the MLflow model framework
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True, If set to False, the model will be placed in a staged state.
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")`,
        logs: `2024-10-08 16:15:15,043 - vipas.model - INFO - Publishing model <model_id> with framework type onnx.
2024-10-08 16:15:19,818 - vipas.model - INFO - File processor.py uploaded successfully.
2024-10-08 16:16:22,952 - vipas.model - INFO - Model <model_id> and related processor launched successfully.`,

    };
    return (
        <>
            <div className="flex my-8 relative">
                <div
                    className="w-[100%] xl:w-[66.66%] px-[24px]"
                    id="basic-usage"
                >
                    <h1 className="mb-3 heading" id="vipas-sdk">
                        Publishing Model
                    </h1>
                    <p className="my-2">
                        The{" "}
                        <a
                            href="https://pypi.org/project/vipas/"
                            className="links !text-[16px]"
                        >
                            Vipas.AI SDK
                        </a>{" "}
                        provides a simple and powerful interface for developers
                        to publish, manage, and deploy AI models. With this SDK,
                        developers can upload their models, configure model
                        processors, and deploy them to the Vipas platform
                        seamlessly. This documentation will guide you through
                        the process of using the SDK to publish and manage
                        models built on various machine learning frameworks,
                        including TensorFlow, PyTorch, ONNX, XGBoost,
                        Scikit-learn, Hugging Face qwen 2.5, Hugging Face llama
                        3, Hugging Face google t5, Hugging Face google mt5,
                        Hugging Face coedit large, Hugging Face dslim bert,
                        Hugging Face distilbert, MLflow and more.
                    </p>

                    <div className="my-6" id="syntax">
                        <h2 className="sub-heading">Syntax</h2>
                        <p className="font-semibold">
                            Import the necessary modules:
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnippets}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="my-2">
                            Publish a model to the Vipas AI platform.
                        </p>
                    </div>
                    <div className="my-6">
                        <h2 className="sub-heading" id="parameters">
                            Parameters
                        </h2>
                        <ul>
                            <li className="link-list">
                                <code>model_id (str) [Required]</code>: The unique identifier for the model. This ID is used to track the model across the platform.
                            </li>
                            <li className="link-list">
                                <code>model_folder_path (Optional[str])</code>:
                                The path to the directory containing the model files that need to be uploaded.
                            </li>
                            <li className="link-list">
                                <code>
                                    model_framework_type (str) [Required]
                                </code>
                                : The framework used for the model (e.g., TensorFlow, PyTorch, ONNX, XGBoost, Scikit-learn, Hugging Face). Each framework has its own nuances in terms of model configuration.
                            </li>
                            <li className="link-list">
                                <code>onnx_config_path (Optional[str])</code>:
                                The path to the ONNX config file (if
                                applicable).
                            </li>
                            <li className="link-list">
                                <code>hf_config_path (Optional[str])</code>: The
                                path to the Hugging Face config file (if
                                applicable).
                            </li>
                            <li className="link-list">
                                <code>mlflow_config_path (Optional[str])</code>:
                                The path to the MLflow config file (if
                                applicable).
                            </li>
                            <li className="link-list">
                                <code>
                                    processor_folder_path (Optional[str])
                                </code>
                                : The path to the processor folder (if using a
                                custom processor).
                            </li>
                            <li className="link-list">
                                <code>processor_image (Optional[str])</code>:
                                The Docker image to use for the processor.
                            </li>
                            <li className="link-list">
                                <code>auto_launch (Optional[bool])</code>:
                                Whether to automatically launch the model after
                                publishing (default: True).
                            </li>
                            <li className="link-list">
                                <code>override_model (Optional[bool])</code>:
                                Whether to override the existing model (default:
                                True).
                            </li>
                        </ul>
                    </div>
                    <div className="my-6" id="returns">
                        <h2 className="sub-heading">Returns</h2>
                        <ul>
                            <li className="link-list">
                                <code>dict (str)</code>: A dictionary containing
                                the status and details of the model publishing
                                process.
                            </li>
                        </ul>
                    </div>
                    <div className="my-6" id="allowed-model">
                        <h2 className="sub-heading">
                            Allowed model frameworks
                        </h2>
                        <ul>
                            <li className="link-list">
                                <strong>MLflow</strong>: An open-source platform
                                for managing the end-to-end machine learning
                                lifecycle. Supports saving and serving models
                                with flexible tracking. Learn more about it
                                here. For file validations and directory
                                structures, visit here.
                                <p>
                                    model_framework_type: "<code>mlflow</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>TensorFlow</strong>: An open-source
                                platform for machine learning, known for deep
                                learning model development. Learn more about it{" "}
                                <a href="" className="">
                                    here
                                </a>
                                . For file validations and directory structures,
                                visit{" "}
                                <a href="" className="">
                                    here
                                </a>
                                .
                                <p>
                                    model_framework_type: "
                                    <code>tensorflow</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>PyTorch</strong>: A flexible and
                                efficient framework for building machine
                                learning and deep learning models. Learn more
                                about it here. For file validations and
                                directory structures, visit here.
                                <p>
                                    model_framework_type: "<code>pytorch</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>ONNX</strong>: An open format built to
                                represent machine learning models, enabling
                                interoperability. Learn more about it here. For
                                file validations and directory structures, visit
                                here.
                                <p>
                                    model_framework_type: "<code>onnx</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>Sklearn</strong>: A simple and efficient
                                tool for data mining and data analysis with
                                machine learning algorithms. Learn more about it
                                here. For file validations and directory
                                structures, visit here.
                                <p>
                                    model_framework_type: "<code>sklearn</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>XGBoost</strong>: A scalable and fast
                                implementation of gradient boosting for
                                supervised learning tasks. Learn more about it
                                here. For file validations and directory
                                structures, visit here.
                                <p>
                                    model_framework_type: "<code>xgboost</code>"
                                </p>
                            </li>
                            <li className="link-list">
                                <strong>Hugging Face</strong>: A hub for
                                state-of-the-art natural language processing
                                (NLP) and deep learning models. Supports
                                transformers, tokenizers, and pipelines for ease
                                of use. Learn more about it here. For file
                                validations and directory structures, visit
                                here.
                                <p>
                                    model_framework_type: "
                                    <code>huggingface</code>"
                                </p>
                            </li>
                        </ul>
                        <p className="my-2">
                            Here is a basic example of how to use the SDK to
                            publish a model from any remote environment:
                        </p>
                        <p className="my-2 font-semibold" id="code-example">
                            Example code for model publish
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={exampleSnippet}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>
                    <div className="my-6" id="examples">
                        <h2 className="sub-heading">Example</h2>
                        <p className="my-2 example-heading">
                            Publishing a Model with Frameworks like TensorFlow,
                            PyTorch, XGBoost, or Scikit-learn
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.generalPublish}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="my-2 example-heading">
                            Publishing an ONNX Model with Configuration{" "}
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.onnxPublish}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <div className="my-4 rounded-[.375rem] border border-[#d7eaf8] bg-note p-[1rem]">
                            <p className="font-semibold text-note">Note</p>
                            <p className="mt-3">
                                For ONNX models, you must provide an ONNX
                                configuration file with extensions like{" "}
                                <code>.pbtxt</code>, <code>.config</code>, or{" "}
                                <code>.txt</code> that describe the input-output
                                mapping.
                            </p>
                        </div>
                        <p className="my-2">
                            Below is an example ONNX configuration for input and
                            output details needed by the model:
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.onnxFileData}
                                language="text"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="my-2 example-heading">
                            Publishing a Hugging Face Model
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.huggingFaceModel}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="font-semibold">
                            Hugging Face Framework Types and Model IDs
                        </p>
                        <div className="my-4">
                            <table class="table-auto border py-2">
                                <thead>
                                    <tr className="border p-2">
                                        <th className="p-2">
                                            Hugging Face Framework Types
                                        </th>
                                        <th className="p-2">
                                            Hugging Face Model IDs
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_qwen_2.5
                                        </td>
                                        <td className="p-2">
                                            Qwen/Qwen2.5-1.5B
                                        </td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_llama_3
                                        </td>
                                        <td className="p-2">
                                            meta-llama/meta-llama-3-8b-instruct
                                        </td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_google_t5
                                        </td>
                                        <td className="p-2">
                                            google-t5/t5-small
                                        </td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_google_mt5
                                        </td>
                                        <td className="p-2">google/mt5-base</td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_coedit_large
                                        </td>
                                        <td className="p-2">
                                            grammarly/coedit-large
                                        </td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_dslim_bert
                                        </td>
                                        <td className="p-2">
                                            dslim/bert-base-NER
                                        </td>
                                    </tr>
                                    <tr className="border p-2">
                                        <td className="p-2">
                                            hugging_face_distilbert
                                        </td>
                                        <td className="p-2">
                                            distilbert/distilbert-base-uncased-finetuned-sst-2-english
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="my-4 rounded-[.375rem] border border-[#d7eaf8] bg-note p-[1rem]">
                            <p className="font-semibold text-note">Note</p>
                            <p className="mt-3">
                                For the Hugging Face models you must provide the
                                above framework types. <br />
                                For Hugging Face models, you must provide a
                                Hugging face configuration file with extensions
                                like .json or .txt that has the Hugging face
                                access token, Hugging Face model id and Hugging
                                Face max tokens.
                            </p>
                        </div>
                        <p className="my-2">
                            Below is an example Hugging Face configuration:
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.hugginFileContent}
                                language="text"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="my-2 example-heading">
                            Publishing a MLflow Model
                        </p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.mlflowModel}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>
                    <div className="my-6" id="publishing-overview">
                        <h2 className="sub-heading">
                            Publishing Process Overview
                        </h2>
                        <p className="my-2">
                            When you publish a model using the Vipas SDK, the
                            following steps occur behind the scenes:
                        </p>
                        <ol className="list-decimal list-inside">
                            <li>
                                <strong>Model Upload</strong>: The SDK uploads
                                the model files from the specified directory.
                                The total size of the files is calculated, and
                                the upload process is logged step-by-step.
                            </li>
                            <li>
                                <strong>Processor Upload (Optional)</strong>: If
                                you are using a custom processor (a custom
                                Python script), the SDK uploads the processor
                                files. This step is optional but can be critical
                                for advanced use cases where model input needs
                                specific transformations.
                            </li>
                            <li>
                                <strong>Processor Staging(Optional)</strong>:
                                After the processor upload, the processor will
                                get staged if the files are properly uploaded.
                            </li>
                            <li>
                                <strong>
                                    Model Staging And Building Processor
                                </strong>
                                : Once the model and its associated files
                                (including the processor, if applicable) are
                                uploaded, the model is placed in a staging
                                state. This stage ensures that all files are
                                correctly uploaded and prepares the model for
                                deployment.
                            </li>
                            <li>
                                <strong>Model Launch (Optional)</strong>: If the
                                auto_launch parameter is set to True, the model
                                will be automatically launched. This means that
                                the model will be deployed and become available
                                for real-time and asynchronous inference. The
                                launch status is logged until the process is
                                completed successfully.
                            </li>
                            <li>
                                <strong>Rollback Mechanism</strong>: If a model
                                is already deployed and a new version is being
                                uploaded, the SDK ensures that the previous
                                version is rolled back in case of any issues
                                during the new model deployment.
                            </li>
                        </ol>
                        <div className="my-4 rounded-[.375rem] border border-[#d7eaf8] bg-note p-[1rem]">
                            <p className="font-semibold text-note">Note</p>
                            <p className="mt-3">
                            The Rollback Mechanism will not occur if you make override_model=False.
                            </p>
                        </div>
                    </div>
                    <div className="my-6" id="logs-example">
                        <h2 className="sub-heading">Logs</h2>
                        <p className="my-2">Once you run the <code>publish()</code> method, you can expect logs similar to the following:</p>
                        <div className="my-4 w-full">
                            <CopyBlock
                                text={codeSnip.logs}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className="my-6">This log sequence shows the entire process of publishing the model, uploading the processor, and successfully launching the model. Any errors or warnings will also be captured in the logs, which can help troubleshoot issues.</p>
                    </div>

                    <div className="my-6" id="error-handling">
                        <h2 className="sub-heading">Error Handling</h2>
                        <p className="my-2">The SDK raises custom exceptions for API responses. Below is a list of possible exceptions and their meanings:</p>
                        <div className='my-4' >
                            <table class="table-auto border py-2">
                                <thead>
                                    <tr className='border p-2'>
                                        <th className='p-2'>Exception</th>
                                        <th className='p-2'>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.ClientException: (400)</code></td>
                                        <td className='p-2'>If model framework type is not supported.</td>
                                    </tr>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.UnauthorizedException (401)</code></td>
                                        <td className='p-2'>Authentication token is missing, invalid, or expired.</td>
                                    </tr>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.NotFoundException (404)</code></td>
                                        <td className='p-2'>If the model/challenge id was not found.</td>
                                    </tr>
                                   
                                    
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.ConnectionException</code></td>
                                        <td className='p-2'>Network connectivity issue or server is unreachable.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <Navigation
                        previous={
                            "Previous: Generating MLflow Configuration Files"
                        }
                        previousNav={"generate-mlflow-config-files"}
                        next={"Next: Retrieve Model Deployment Logs"}
                        nextNav={"retrieve-model-deployment-logs"}
                    />
                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    <p className="font-semibold my-8 ">On this page</p>
                    <ul className="border-t-2 py-4">
                        <li className="link-list ">
                            <a href="#vipas-sdk" className="links">
                                Vipas SDK
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#syntax" className="links">
                                Syntax
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#parameters" className="links">
                                Parameters
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#returns" className="links">
                                Returns
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#allowed-model" className="links">
                                Allowed model frameworks
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#examples" className="links">
                                Example
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#publishing-overview" className="links">
                            Publishing Process Overview
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href='#logs-example' className="links">
                                Logs
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href='#error-handling' className="links">
                                Error Handling
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default PublishSDK;
