import React from 'react'
import { CopyBlock, anOldHope } from 'react-code-blocks';
import Navigation from '../Navigation';
import SEOHelmet from '../../utils/SEOHelmet';
function GenerateMlflowConfig() {
    const codeSnippets = {
        functionSignature: `vipas.model.ModelClient.mlflow_configuration_files(
            model_id:str, # Required: unique identifier for the model
            custom_runtime: Optional[bool] = False # Optional: Indicates whether to use a custom runtime configuration. This is applicable only if a custom runtime is supported. The default value is False.
             ) -> str
`,
returnExample:` /path/to/mlflow_config_<model_id>.zip`,
        dockerBuild:`docker build -t <dockerhub-username>/<repository-name>:<tag-name> .`,
        dockerPush:`docker push <dockerhub-username>/<repository-name>:<tag-name>`,
        modelSettings:`{
            "docker_image" : <dockerhub-username>/<repository-name>:<tag-name>,
            "docker_token" : <your dockerhub personal access token>
           }`,
        example:`from vipas.model import ModelClient
from vipas.exceptions import ClientException
from vipas.logger import LoggerClient
        
# Create a LoggerClient instance
logger_client = LoggerClient(__name__)
        
try:
    # Define model details
    model_id = "mdl-abc123xyz" # Required, unique identifier for the model
    custom_runtime = True # Optional, Indicates whether to use a custom runtime configuration. This is applicable only if a custom runtime is supported. The default value is False.
           
    framework_type = "mlflow"  # Framework type for the model. In this     case, it's "mlflow" to indicate the model uses the MLflow framework.
    mlflow_config_path = "/path/to/mlflow_config.json" # Path to the MLflow JSON configuration file, containing settings like the model's Docker image and personal access token
        
    # Create a ModelClient instance
    model_client = ModelClient()
        
    # Call the mlflow_configuration_files method to retrieve the configuration zip file
    zip_file_path = model_client.mlflow_configuration_files(
    model_id=model_id, 
    custom_runtime=custom_runtime
    )
    logger_client.info(f"Configuration file saved at: {zip_file_path}")
        
        
    # Publish the model
    model_response = model_client.publish(
                model_id=model_id,
                model_framework_type=framework_type,                      
                mlflow_config_path=mlflow_config_path  # The mlflow_config_path file is generated from the above Step 3 (creating mlflow_config.json).
                auto_launch=True,  # Whether to automatically launch the model after upload, Default True, If set to False, the model will be placed in a staged state.
                override_model=True  # Whether to override existing model deployments, Default True
                )
    # Log the model publish response
    logger.info(model_response)
        
except ClientException as e:
    logger_client.error(f"ClientException occurred: {e}")
except Exception as e:
    logger_client.error(f"An unexpected error occurred: {e}")`,
        loggingExample: `2024-11-20 10:15:45,125 - vipas.mlflow - INFO - Fetching MLflow configuration files for model ID: mdl-abc123xyz.
2024-11-20 10:16:15,302 - vipas.mlflow - INFO - MLFlow configuration files successfully saved for model ID: mdl-abc123xyz at /path/to/mlflow_config_mdl-abc123xyz.zip.`,
    };

    return (
        <> <SEOHelmet
        title={"Generate MLflow confguration files"}
        description={
            "Generating MLflow configuration files using Vipas SDK."
        }
        canonicalUrl={
            "https://docs.vipas.ai/developer-docs/generate-mlflow-config-files"
        }
    />
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className='mb-3 heading'>Generating MLflow Configuration Files</h1>
                    <p className='my-2'>The <strong>Vipas.AI SDK</strong> provides functionality to generate and retrieve the necessary configuration files for deploying MLflow models on the platform. The <code>mlflow_configuration_files</code> function allows users to fetch a preconfigured zip file containing a Dockerfile and a <code>model-settings.json</code> file tailored for MLflow deployment.
</p>
                    <div className="my-6" id='key-features'>
                        <h2 className='sub-heading'>Key Features of the <code>mlflow_configuration_files</code> Function</h2>
                        <ul className='list-decimal list-inside'>
                            <li className='link-list'><strong>Configuration Retrieval</strong>: Fetch a preconfigured zip file that includes deployment essentials such as Dockerfile and model settings.
                            </li>
                            <li className='link-list'><strong>Customizable Runtime</strong>: Option to specify whether a custom runtime is required.
                            </li>
                            <li className='link-list'><strong>Unique Output Path</strong>: Each zip file is uniquely named based on the provided model_id.</li>
                            <li className='link-list'><strong>Automatic Reformatting</strong>: Ensures the model-settings.json file is correctly formatted.
                            </li>
                            <li className='link-list'><strong>Error Handling</strong>: Provides detailed logging and raises appropriate exceptions for failures.
                            </li>

                        </ul>
                    </div>
                    <div className="my-6" id='basic-usage'>
                        <h2 className='sub-heading' >Basic Usage</h2>
                        <p className='my-2'>The <code>mlflow_configuration_files</code> function simplifies the process of preparing an MLflow model for deployment. Below is a step-by-step guide to using this function.
</p>
                      
                    </div>
                    <div className="my-6" id='function-signature'>
                        <h2 className='sub-heading' >Function Signature</h2>
                       
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.functionSignature}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>
                    <div className="my-6" id='parameters'>
                        <h2 className='sub-heading' >Parameters</h2>
                        <ul className='my-2'>
                            <li className='link-list'><code>model_id [Required]</code> : The unique identifier for the model. This ID is used to track the model across the platform.
                            </li>
                            <li className='link-list'><code>custom_runtime[Optional]</code> : Indicates whether to use a custom runtime configuration. This is applicable only if a custom runtime is supported. The default value is False.
                            </li>
                        </ul>
                    </div>
                    <div className="my-6" id='returns'>
                        <h2 className='sub-heading' >Returns</h2>
                        <ul className='my-2'>
                            <li className='link-list'><code>str</code> : The absolute file path of the saved configuration zip file.
                            </li>
                        </ul>
                        <h2 className='font-semibold' >Example</h2>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.returnExample}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>
                   
                    <div className="my-6" id='directory-structure'>
                        <h2 className='sub-heading' >Directory Structure</h2>
                        <div className="">
                                  <p>mlruns/</p>
                                  <p>&emsp;├── 0/</p>
                                  <p>      &emsp;&emsp;&emsp; ├── &lt;run-id&gt;/</p>
                                  <p>        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;       ├── artifacts/</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├── model/</p>                                  
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; |&emsp;&emsp;&emsp;├── MLmodel</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; |&emsp;&emsp;&emsp;├── model.pkl</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; |&emsp;&emsp;&emsp;├── requirements.txt</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; |&emsp;&emsp;&emsp;└── conda.yaml</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├── Dockerfile</p>
                                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;└── model-settings.json</p>
                                </div>
                                <div className='my-4 rounded-[.375rem] border border-[#d7eaf8] bg-note p-[1rem]'>
              <p className='font-semibold text-note'>Important</p>
                <p className="mt-3">
                Ensure the Python version specified in the Dockerfile matches the Python version used to train the model. Update the Dockerfile if needed.
              </p>
               
              </div>
                    </div>

                    <div className="my-6" id='building-publishing'>
                        <h2 className='sub-heading' > Building and Publishing an MLflow-Based Model
</h2>
<p className='my-2'>To deploy an MLflow-based model, you need to package it as a Docker image, push the image to DockerHub, and provide a configuration file for the publishing process. Below are the steps to build and push the Docker image, create the required configuration file, and publish your model seamlessly.
</p>
<p className='my-2'><strong>Step 1</strong>: Run the command to build a docker image. </p>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.dockerBuild}
                                language="shell"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className='my-2'><strong>Step 2</strong>: Run the command to push the docker image to dockerhub</p>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.dockerPush}
                                language="shell"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className='my-2'><strong>Step 3</strong>: Create a mlflow_config.json</p>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.modelSettings}
                                language="json"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p className='my-2'>To publish an MLflow-based model, pass the mlflow_config file path along with model_id and model_framework_type to the publish function.</p>
                    </div>

                    <div className="my-6" id='example'>
                        <h2 className='sub-heading' >Example Usage</h2>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.example}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>

                    <div className="my-6" id='logging'>
                        <h2 className='sub-heading' >Logging Example for Configuration File Generation</h2>
                        <p className='my-2'>The SDK provides detailed logging to ensure visibility into the configuration file retrieval process. Below is an example log sequence:</p>
                        <div className='w-full my-4' >
                            <CopyBlock
                                text={codeSnippets.loggingExample}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>
                    <div className="my-6" id='error-handling'>
                        <h2 className='sub-heading' >Error Handling</h2>
                        <p className='my-2'>The SDK raises custom exceptions for API responses. Below is a list of possible exceptions and their meanings:</p>
                        <div className='my-4' >
                            <table class="table-auto border py-2">
                                <thead>
                                    <tr className='border p-2'>
                                        <th className='p-2'>Exception</th>
                                        <th className='p-2'>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.ClientException(404)</code></td>
                                        <td className='p-2'>If the model/challenge id was not found.</td>
                                    </tr>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.UnauthorizedException (401)</code></td>
                                        <td className='p-2'>Authentication token is missing, invalid, or expired.</td>
                                    </tr>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.ConnectionException</code></td>
                                        <td className='p-2'>Network connectivity issue or server is unreachable.</td>
                                    </tr>
                                    <tr className='border p-2'>
                                        <td className='p-2'><code>vipas.exceptions.ClientException</code></td>
                                        <td className='p-2'>A generic client-side error occurred.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <Navigation previous={'Previous: Create Models'} previousNav={'vipas-sdk-create-model'} next={"Next: Publishing Model"} nextNav={"sdk-publish"} />

                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    <p className='font-semibold my-8 '>On this page</p>
                    <ul className='border-t-2 py-4'>
                    <li className='link-list '><a href="#key-features" className='links'>Key Features</a></li>

                    <li className='link-list '><a href="#basic-usage" className='links'>Basic Usage</a></li>
                        <li className='link-list '><a href="#function-signature" className='links'>Function Signature</a></li>
                        <li className='link-list '><a href="#parameters" className='links'>Parameters</a></li>
                        <li className='link-list '><a href="#returns" className='links'>Returns</a></li>
                        <li className='link-list '><a href="#directory-structure" className='links'>Directory Structure</a></li>
                        <li className='link-list '><a href="#building-publishing" className='links'>Building and Publishing an MLflow-Based Model</a></li>
                        <li className='link-list '><a href="#example" className='links'>Example Usage</a></li>

                        <li className='link-list '><a href="#logging" className='links'>Logging</a></li>
                        <li className='link-list '><a href="#error-handling" className='links'>Error Handling
</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default GenerateMlflowConfig
